import React from 'react';
import { Formik } from 'formik';
import { ProfessionalDropdown } from './professional-dropdown';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { SendReminder } from './send-reminder';
import { WhatsappList } from './whatsapp-list';
import { Loading, DropdownForm, Form, Icons } from '../../../../components';
import { ReminderFormT } from '#interfaces/components';
import { FormDatePicker } from '#components/shadcn';

export const RemindersForm: React.FC<ReminderFormT> = ({
  offices,
  professionals,
  onChange,
  onClick,
  openList,
  children,
  onChangeRoute,
  reminders,
}) => {
  const { initialValues } = useForm(offices, reminders);
  const dateFieldName = 'date';
  const officeFieldName = 'office';
  const professionalFieldName = 'professional';

  return (
    <Formik initialValues={initialValues} onSubmit={useSubmit(onChange)}>
      {({ isSubmitting }) => (
        <>
          <span
            className="cursor-pointer flex items-center text-blue-500 transition-transform hover:translate-x-[-10px] mb-4"
            onClick={() => onChangeRoute('/schedule')}
          >
            <Icons className="w-4 h-4 mr-px" type="arrow_back" />
            <small>Volver</small>
          </span>
          <Form className="w-full min-w-[500px]" showActions={false} autoSubmit>
            {isSubmitting && <Loading />}
            <div className="flex gap-x-10">
              <div className="w-full">
                <label
                  htmlFor="input"
                  className="mb-1.5 block text-sm font-medium"
                >
                  Fecha
                </label>
                <FormDatePicker
                  name={dateFieldName}
                  className="h-[38px] text-sm"
                />
              </div>
              <div className="w-full">
                <ProfessionalDropdown
                  name={professionalFieldName}
                  professionals={professionals}
                />
              </div>
              {offices?.length > 0 ? (
                <div className="w-full">
                  <DropdownForm
                    name={officeFieldName}
                    data={offices}
                    label="Sucursal"
                    keyId="id_sucursal"
                    keyData="sucursal"
                  />
                </div>
              ) : null}
              <div className="flex items-center mt-4">
                <WhatsappList onClick={openList} />
                <SendReminder
                  onClick={onClick}
                  name={dateFieldName}
                  disabled={!reminders?.length}
                />
              </div>
            </div>
            {children}
          </Form>
        </>
      )}
    </Formik>
  );
};
