import React from 'react';
import { Switch, Icons } from '../../components';
import { useFieldProps } from '../../hooks';
import { RemindersGridRowT } from '#interfaces/components';

export const Row: React.FC<RemindersGridRowT> = ({
  data,
  switchFieldName,
  onChange,
  keys,
}) => {
  const {
    field: { value },
  } = useFieldProps({ name: switchFieldName });
  const [checked, setChecked] = React.useState(value);

  React.useEffect(() => {
    setChecked(value);
  }, [value]);

  const onChangeHandler = () => {
    setChecked(!checked);
    onChange(data);
  };
  const reminder_response = data['reminder_response'];

  return (
    <div className="flex bg-slate-50 border rounded-md p-2 my-2 px-8">
      <Switch onChange={onChangeHandler} checked={checked} />
      {keys.map((k, index) => (
        <p className="mx-8" key={index}>
          {data[k.key]}
        </p>
      ))}
      {reminder_response && (
        <div className="flex bg-amber-100 py-1 px-2 rounded-md mt-2">
          <Icons className="h-6 w-6 mr-2" type="warning" />
          <p>{reminder_response}</p>
        </div>
      )}
    </div>
  );
};
