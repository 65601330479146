import React from 'react';
import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Copy, Title } from '#components/commons';

export const Packs: React.FC<any> = ({ onClick, classname }) => {
  return (
    <div
      className={classNames(
        'border bg-blue-50 border-blue-300 rounded-sm before:absolute before:left-0 before:right-0 before:-z-50 before:h-full transform-gpu',
        classname,
      )}
    >
      <div className="mx-auto max-w-7xl p-3">
        <div className="flex">
          🖋💊
          <div className="ml-2">
            <Title className="text-base"> Prueba el módulo de Recetas!</Title>
            <Copy className="mt-1">
              Pack libre y gratuito hasta el{' '}
              <strong className="text-slate-900">30 de septiembre</strong>.
            </Copy>
          </div>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
          <button
            type="button"
            className="flex rounded-md p-1 hover:bg-blue-100/50 focus:outline-none focus:bg-blue-50"
            onClick={onClick}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-5 w-5 text-blue-500" />
          </button>
        </div>
      </div>
    </div>
  );
};
