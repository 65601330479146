import { axiosInstance, platform } from '../utils';

export function updateSistemConfig(params: { [key: string]: any }) {
  const body = {
    tablas: {
      opcionbuscar: 'guardarparametros',
      parametros: params,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export function professionalsConfig(params: { [key: string]: any }) {
  const body = {
    tablas: {
      opcionbuscar: 'guardarprofesional',
      profesionales: params,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export function updateTimetableConfig(schedule: { [key: string]: any }) {
  const body = {
    tablas: {
      opcionbuscar: 'guardarprofhorario',
      profesionales: schedule,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export function updateAbsencesConfig(absence: { [key: string]: any }) {
  const body = {
    tablas: {
      opcionbuscar: 'guardarprofausencia',
      profesionales: absence,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export function updateSpecialtiesConfig(specialty: { [key: string]: any }) {
  const body = {
    tablas: {
      opcionbuscar: 'guardarespecialidad',
      especialidades: specialty,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export function updateHolidaysConfig(holiday: { [key: string]: any }) {
  const body = {
    tablas: {
      opcionbuscar: 'guardarferiado',
      feriados: holiday,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export async function getWappTemplates(region: string) {
  const body = {
    whatsapp: {
      opcion: region,
    },
    plataforma: platform,
  };
  return await axiosInstance.post('/whatsapptemplates', body);
}

export function updateOfficesConfig(office: { [key: string]: any }) {
  const body = {
    tablas: {
      opcionbuscar: 'guardarsucursal',
      sucursales: office,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export function updateMedInsConfig(medIns: {
  id_os: number;
  codigo_os: string;
  obra_social: string;
  domicilio: string;
  localidad: string;
  provincia: string;
  telefono: string;
  fax: string;
  e_mail: string;
  cuit: string;
  observaciones: string;
  cant_prest: number;
  iva_ob: number;
  iva_ad: number;
  cargar_por_periodo: string;
  cliente_ftp: string;
  inhabilitada: boolean;
  opcion: 'nuevo' | 'modificar' | 'eliminar';
  rowversion?: string;
}) {
  const body = {
    tablas: {
      opcionbuscar: 'guardaros',
      obrasocial: medIns,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export function updatePlansConfig(medIns: { [key: string]: any }) {
  const body = {
    tablas: {
      opcionbuscar: 'guardarosplan',
      obrasocial: medIns,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export function updateGroupsConfig(medIns: {
  codigo_os: string;
  id_os: number;
  obra_social: string;
  opcion: 'modificar' | 'nuevo' | 'eliminar';
  grupos: {
    id_grupo: number;
    id_os: number;
    cod_grupo: string;
    descripcion: string;
    rowversion?: string;
  }[];
}) {
  const body = {
    tablas: {
      opcionbuscar: 'guardarosgrupos',
      obrasocial: medIns,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export function updateBenefitsConfig(medIns: { [key: string]: any }) {
  const body = {
    tablas: {
      opcionbuscar: 'guardarosplanprest',
      obrasocial: medIns,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export function updateUsersConfig(user: { [key: string]: any } | null) {
  const body = {
    tablas: {
      opcionbuscar: 'guardarusuario',
      usuario: user,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}

export function copyPlanPrest(params: { [key: string]: any }) {
  const body = {
    CopyItems: {
      ...params,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/medicalinsurances/services/copy', body);
}

export function getProvinces(countryISO: string) {
  return axiosInstance.get(`states/${countryISO}`);
}
