import React from 'react';
import { DeleteBenefitForm } from './DeleteBenefitForm';
import { BenefitForm } from './BenefitForm';
import { EmptyState, Modal, TabSection } from '../../../../../components';
import { useForm, useSetHeight } from '../../../../../hooks';
import { SettingsBenefits } from '#components/Tables';
import { BenefitGeneralFormT } from '#interfaces/components';

const BenefitsForm: React.FC<BenefitGeneralFormT> = ({
  benefits,
  translations,
  selectedMed,
  selectedPlan,
  specialties,
  onChange,
  openTabAction,
  closeTabAction,
  isOpenTabAction,
  config,
}) => {
  const defaultBenefit = {
    id_plan: 0,
    id_benefit: 0,
    id_benefit_to_do: null,
    id_group: 0,
    id_specialty: null,
    code_medical_insurance: '',
    code_medical_insurance_bill: '',
    description: '',
    billing_medical_insurance: 0,
    billing_patient: 0,
    tooth: '',
    tooth_face: false,
    catastral: false,
    intern: false,
    enabled: true,
    draw: '',
    unit: '',
    group_belongs: false,
    group_belongs_name: null,
    amount: 0,
    amount_patient: 0,
    amount_unit: 0,
    is_usd: false,
    age: '',
  };
  const [groups, setGroups] = React.useState(selectedMed?.grupos);
  const [benefitRow, setBenefitRow] = React.useState<any>(defaultBenefit);
  const height = useSetHeight(290);
  const {
    isOpenForm: isOpenDelete,
    closeForm: closeDelete,
    openForm: openDelete,
  } = useForm();

  React.useEffect(() => {
    setGroups(selectedMed?.grupos);
  }, [selectedMed]);

  const onCancelHandler = () => {
    setBenefitRow(false);
    closeTabAction();
    closeDelete();
  };

  const actions = (benefit: [key: string], action: string) => {
    const events: { [key: string]: any } = {
      delete: openDelete,
      details: openTabAction,
    };
    setBenefitRow(benefit);

    return !!events[action] ? events[action]() : setBenefitRow(defaultBenefit);
  };

  return (
    <div
      className="overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      {!isOpenTabAction && (
        <>
          {benefits?.length ? (
            <SettingsBenefits
              data={{ benefits, groups }}
              action={actions}
              config={{ translations, ...config }}
            />
          ) : (
            <EmptyState
              title="Ups!"
              copy="La obra social seleccionada no tiene prestaciones definidas."
            />
          )}
        </>
      )}
      <Modal
        title="Eliminar prestación"
        isOpenModal={isOpenDelete}
        closeModal={onCancelHandler}
        openModal={openDelete}
      >
        <DeleteBenefitForm
          benefit={benefitRow}
          selectedPlan={selectedPlan}
          selectedMed={selectedMed}
          onChange={onChange}
        />
      </Modal>
      <TabSection
        className="mt-5"
        isOpen={isOpenTabAction}
        close={onCancelHandler}
        open={openTabAction}
      >
        <BenefitForm
          benefit={benefitRow}
          selectedPlan={selectedPlan}
          groups={groups}
          specialties={specialties}
          selectedMed={selectedMed}
          onChange={onChange}
          onCancel={onCancelHandler}
        />
      </TabSection>
    </div>
  );
};

export default BenefitsForm;
