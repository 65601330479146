import React from 'react';
import { useFieldProps } from '../../../../hooks';
import { WithIcon } from '../../../../components';

export const SendReminder: React.FC<{
  onClick: (value: any, dataSelected: any) => void;
  name: string;
  disabled: boolean;
}> = ({ onClick, name, disabled }) => {
  const {
    field: { value },
  } = useFieldProps({ name });
  const {
    field: { value: dataSelected },
  } = useFieldProps({ name: 'turns_to_send' });

  return (
    <WithIcon
      disabled={disabled}
      className="h-6 w-6"
      onClick={() => onClick(value, dataSelected)}
      text="Enviar mensajes"
      type="send"
    />
  );
};
